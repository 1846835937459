import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../../images/logo.webp";
class Footer2 extends Component {
  render() {
    return (
      <>
        <footer className="footer-white bg-white ">
          <div className="footer-top  footer-style2  bt0">
            <div className="container">
              <div className="row">
                <div className="col-md">
                  <div className="widget widget_info">
                    <Link to={"/"}>
                      <img loading="lazy" src={logo} width="150px" alt="" />
                    </Link>
                    <hr />
                  </div>
                  <div className="widget">
                    <ul className="list-inline ft-social-bx">
                      <li>
                        <Link
                          onClick={() =>
                            window.open(
                              "https://www.facebook.com/pacificindia.in/",
                              "_blank"
                            )
                          }
                          className="btn outline button-md">
                          <i className="fa fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() =>
                            window.open(
                              "https://www.instagram.com/pacificindia.in/",
                              "_blank"
                            )
                          }
                          className="btn outline button-md">
                          <i className="fa fa-instagram"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() =>
                            window.open(
                              "https://www.linkedin.com/company/pacific-development-corporation-ltd/",
                              "_blank"
                            )
                          }
                          className="btn outline button-md">
                          <i className="fa fa-linkedin"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* <div className="col-md">
                  <div className="widget footer_widget">
                    <h6 className="footer-title">Portfolio</h6> <hr />
                    <ul>

                      <li>
                        <Link to={"/real-estate"}>Real Estate</Link>
                      </li>
                      <li>
                        <Link onClick={() => window.open("https://www.pacificmalls.in")}>
                          Shopping Malls </Link>
                      </li>
                      <li>
                        <Link to={"/education"}>Education</Link>
                      </li> 
                      <li>
                        <Link to={"/hospitality"}>Hospitality</Link>
                      </li>
                      
                    </ul>
                  </div>
                </div> */}
                <div className="col-md">
                  <div className="widget footer_widget">
                    <h6 className="footer-title">Policies</h6> <hr />
                    <ul>
                      <li>
                        <Link to="/terms-and-conditions">
                          Terms & Conditions
                        </Link>
                      </li>
                      <li>
                        <Link to={"/privacy"}>Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to={"/cancellation-and-refund-policy"}>
                          Cancellation & Refund policy
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.pacificmalls.in/my-pacific-rewards-terms-and-conditions.html">
                          My Pacific Rewards -  Terms & Conditions
                        </Link>
                      </li>
                    </ul>
                    <div className="p-t0 p-b20">
                      <Link
                        onClick={() =>
                          window.open(
                            "https://drive.google.com/file/d/1zhU5Am1INZaCtcvEyXVVwLRy5EendtBr/view?usp=sharing",
                            "_blank"
                          )
                        }
                        className="button-sm btn text-white">
                        Unsecured Creditors Meeting{" "}
                        <small>
                          <i className="ti-arrow-right"></i>
                        </small>{" "}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-md">
                  <div className="widget footer_widget">
                    <h6 className="footer-title">Media</h6> <hr />
                    <ul>
                      <li>
                        <Link to={"/media"}>Media Coverage</Link>
                      </li>
                      <li>
                        <Link to={"/blog"}
                          >
                          Blog
                        </Link>
                      </li>
                      {/* <li>
                        <Link onClick={() => window.open("https://pacificindia.in/sitemap.xml", "_blank")}>
                          Sitemap
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>

                <div className="col-md">
                  <div className="widget footer_widget">
                    <h6 className="footer-title">Get in Touch</h6> <hr />
                    <ul>
                      <li>
                        <p>
                          <strong>Pacific Development Corporation</strong>
                        </p>
                      </li>
                      <li>
                        <Link
                          onClick={() =>
                            window.open(
                              "https://goo.gl/maps/sMCSj3c5yuVENmmK7",
                              "_blank"
                            )
                          }>
                          Tagore Garden, Najafgarh Road, New Delhi 110018
                        </Link>
                      </li>
                      <li>
                        <a href="tel:011 - 69276600">Tel: 011 - 69276600</a>
                      </li>
                      <li>
                        <a href="mailto:info@pacificindia.in">
                          Email: info@pacificindia.in
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                  © 2023{" "}
                  <span className="text-primary">
                    Pacific Development Corporation Ltd.
                  </span>{" "}
                  All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer2;
