import React, { useRef } from 'react';
import Footer2 from '../../layout/footer/footer2';
import { Link } from "react-router-dom";
import Booking from "../../elements/contact/booking";
import snooker from '../../../images/snooker.png'
import tenis from '../../../images/tenis.png'
import sportcourt from '../../../images/sportcourt.png'
import hotels from '../../../images/hotels.png'
import table from '../../../images/table.png'
import gym from '../../../images/gym.png'
import club from '../../../images/club.png'
import carrom from '../../../images/carrom.png'
import dailyneed from '../../../images/dailyneed.png'
// If using npm

import logoeiffelhomes from "../../../images/logoeiffelhomes.png";


export default function Bookingpage() {
  const requestCallbackRef = useRef(null);

  const handleBookNowClick = () => {
    requestCallbackRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <div class="container-fluid p-0 ">


        <div className="navbar m-0  d-flex  align-items-center px-4 py-4">
          <div className="nav-icon">
            <img src={logoeiffelhomes} className='w-75' alt='logo' />
            {/* <h4 className='text-white mb-0'>Pacific Mall</h4> */}
          </div>
          <div className="social-icons">

            <div className="icons d-flex  gap-2 ">
              <a href='tel:+918979939101'><button className="btn  button-md m-b10 button-hover-style "> Call Us  </button></a>
              {/* <li style={{ listStyle: 'none' }}>
                <Link
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/pacificindia.in/",
                      "_blank"
                    )
                  }
                  className="btn button-icon-style button-md m-b10 ">
                  <i className="fa fa-facebook "></i>
                </Link>
              </li> */}
              {/* <a style={{ listStyle: 'none' }}>
                <Link
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/pacificindia.in/",
                      "_blank"
                    )
                  }
                  className="btn button-icon-style button-md m-b10 ">
                  <i className="fa fa-instagram "></i>
                </Link>
              </a> */}
              <a href='https://www.instagram.com/pacificindia.in/' className="btn button-icon-style button-md m-b10 ">                        <i className="fa fa-instagram "></i>
              </a>
              <a href='https://www.linkedin.com/company/pacific-development-corporation-ltd/' className="btn button-icon-style button-md m-b10 ">                                              <i className="fa fa-linkedin "></i>

              </a>
              <a href='https://www.facebook.com/pacificindia.in/' className="btn button-icon-style button-md m-b10 ">                        <i className="fa fa-facebook "></i>
              </a>
              {/* <li style={{ listStyle: 'none' }}>
                <Link
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/pacific-development-corporation-ltd/",
                      "_blank"
                    )
                  }
                  className="btn button-icon-style button-md m-b10 ">
                  <i className="fa fa-linkedin "></i>
                </Link>
              </li> */}
            </div>


          </div>


        </div>

        {/* <!-- Banner Section --> */}
        <div class="banner-image d-flex justify-content-center align-items-center text-center " id="requestCallback" ref={requestCallbackRef} >
          <div class="container-fluid m-4">
            <div class="row d-flex justify-content-between gap-4">

              <div class="col-sm-12 col-lg-5 col-md-5 d-flex align-items-center p-2 card-banner">
                <div className="p-3 text-center ">
                  <h3 className='fw-bolder text-white'>Welcome to Pacific Eiffel Homes</h3>

                  <div class="mb-3 ">
                    <p className='fs-6 fw-normal  text-white'>With the increasing demand for luxurious residential properties in Dehradun, Pacific Eiffel Homes has come up to offer exclusive residential options in the prime location of Dehradun

                    </p>
                  </div>

                  {/* <button class="w-100 mb-4 text-white border-0 rounded p-3">Get in Touch Today! </button> */}

                </div>

              </div>

              <div class="col-sm-12 col-lg-5 col-md-5 pacific-mall-form p-2" >
                <div className='p-3'>
                  <Booking />
                  {/* <h4 className='text-justify'>Request a call back

</h4>
                  <div class="mb-3">
                    
                    <input type="email" class="form-control px-2 py-4 border rounded" placeholder='Full Name' aria-describedby="emailHelp" />
                  </div>
                  <div class="mb-3">
                    <input type="email" class="form-control  px-2 py-4  border rounded" placeholder='Email address' aria-describedby="emailHelp" />
                  </div>
                  <div class="mb-3">
                    <input type="text" class="form-control px-2 py-4  border rounded" placeholder='Phone' />
                  </div> */}
                  {/* <div>
                   <button class="w-100 fw-bold mb-4 text-white border-0 rounded p-3 ">Enquire Now</button>

                  </div> */}
                </div>

              </div>
            </div>

          </div>
        </div>
        <div className="container mt-4 mb-4">
          <div className="Cta-section pacific-mall  ">
            {/* <p className='fs-6 fw-bold mb-2  text-justified'>Welcome To Pacific Mall</p> */}
            <h3 className='fw-normal'>Luxury Apartments For Sale in Dehradun
            </h3>
            <p>The luxury apartments are located on the beautiful Shastradhara Road surrounded by serenity and natural beauty. 3 BHK and 4BHK apartments spread across 2299 and 3173 SqFt and penthouses have the advantages of modern Dehradun city. Clean environment, clean roads, fresh air, convenience and other essential things for leisure and exclusive experiences make it a preferred destination for those looking for apartments in Dehradun.
            </p>


          </div>

          <hr className="horizontal-line" />
          <h3 className='fw-normal  ' style={{ fontSize: '37px' }}> Find Your Perfect Space: Explore Our 3BHK, 4 BHK Apartments and Penthouses
          </h3>
          <p>Get the warmth of nature with mesmerizing scenic views of Dehradun at Pacific Eiffel Homes
            3BHK &amp; 4BHK Apartments and Penthouses. The earthy design of these luxurious spaces
            welcomes the art of living.</p>
          <hr className="horizontal-line" />

        </div>
        <div className="container" >
          <div className="Cta-section mt-4 mb-4 ">
            {/* <p className='fs-6 fw-bold mb-2'>Buy our 4BHK flats</p> */}
            <h3 className='fw-normal'>3 BHK Apartments in Dehradun
            </h3>
            <p>Our contemporary 3 BHK apartments in Dehradun, are designed in a wide space combining the perfect union of space and seclusion. The wide apartments have impressive features making them perfect for living-
            </p>
            <ul>
              <li className='mb-2'>	<span className='fw-bold'>Unit Size</span> - 2299 sq. ft & 2443 sq. ft</li>
              <li className='mb-2'>Sprawling living space with 3 toilets and study </li>
              <li className='mb-2'>	Bathrooms with Gleaming marble floors </li>
              <li className='mb-2'>	Cutting-edge fittings in kitchens with individual dining area </li>
            </ul>
            {/* modal starts here */}

            {/* <a href="/requestCallback"><button type="button" class="btn text-white fw-normal" >
              Book Now
            </button></a>  */}
            <button type="button" className="btn text-white fw-normal" onClick={handleBookNowClick}>
              Book Now
            </button>



          </div>
          <hr className="horizontal-line" />
        </div>
        <div className="container">
          <div className="Cta-section mt-4 mb-4 ">
            {/* <p className='fs-6 fw-bold mb-2'>4BHK Apartments in Dehradun</p> */}
            <h3 className='fw-Normal'>4BHK Apartments in Dehradun
            </h3>
            <p>Celebrate moments of solitude, with 4 BHK luxury apartments in Dehradun that give you a perfect environment for a luxurious lifestyle.

              Pacific Eiffel Homes brings a wider space for big families with elegant 4 BHK apartments where every space is crafted in a modular way giving all the luxury amenities. These tower units are built with ample spaces to introduce modern housing with features like

            </p>
            <ul>
              <li className='mb-2'><span className='fw-bold'>Unit Size</span> - 3173 sq. ft to 4008 sq. ft</li>
              <li className='mb-2'>	Modern-themed interior with minimal design </li>
              <li className='mb-2'>	Spacious Balcony for comfortable sitting  </li>
              <li className='mb-2'>		Expansive views of the tranquil surroundings provide a serene escape. </li>
            </ul>
            {/* modal starts here */}

            <button type="button" className="btn text-white fw-normal" onClick={handleBookNowClick}>
              Book Now
            </button>



          </div>
          <hr className="horizontal-line" />
        </div>
        <div className="container  mt-4 mb-4 ">
          <div className="Cta-section">
            {/* <p className='fs-6 fw-bold mb-2'>Buy our PentHouses </p> */}
            <h3 className='fw-normaler'>Luxurious Penthouses in Dehradun  </h3>
            <p>Explore luxurious penthouses designed with precision giving you a soulful experience of modern living. These ready-to-move-in residential projects are perfect for intimate gatherings with your family and friends while enjoying the natural beauty of Dehradun.
            </p>
            <ul>
              <li className='mb-2'>	<span className='fw-bold'>Units</span>- 8</li>
              <li className='mb-2'>Open-plan living spaces with sharp finishes </li>
              <li className='mb-2'>	Private terrace for personal gatherings </li>
              <li className='mb-2'>	Duplex property with  Study, toilet, and SR </li>
            </ul>
            {/* modal starts here */}


            <button type="button" className="btn text-white fw-normal" onClick={handleBookNowClick}>
              Book Now
            </button>



          </div>

        </div>
        <div className="banner-ammenties" >
          <div className="container ammenties  ">
            <h2 className="text-center  text-white">  Premium Amenities  - Pacific Eiffel Homes</h2>
            <p className='text-white text-center'>Luxury apartments in Dehradun unites modern conveniences with natural beauty. The natural and serene environment at a good locality like Shastradhara Road contributes to a peaceful and healthy living. </p>
            <div className="row  d-flex align-items-center">
              <div className="col-12 col-lg-3 col-md-3 ">
                <div class="card ammenties-card d-flex justify-content-center  align-items-center">
                  <img class="" src={snooker} alt="Snooker" />

                  <h5 class="card-title text-center">Open-Air Theatre</h5>


                </div>
              </div>

              <div className="col-12 col-lg-3 col-md-3">
                <div class="card ammenties-card d-flex  justify-content-center align-items-center">
                  <img class="" src={tenis} alt="tenis" />


                  <h5 class="card-title text-center">Basketball & Badminton Court</h5>


                </div>
              </div>
              <div className="col-12 col-lg-3 col-md-3 ">
                <div class="card ammenties-card  d-flex justify-content-center align-items-center">
                  <img class="" src={sportcourt} alt="sportcourt" />

                  <h5 class="card-title text-center">Table Tennis </h5>


                </div>
              </div>
              <div className="col-12 col-lg-3 col-md-3">
            
                <div class="card ammenties-card justify-content-center align-items-center d-flex align-items-center">
                  <img class="" src={club} alt="club" />

                  <h5 class="card-title text-center">Clubhouse</h5>


                </div>
              </div>
            </div>
            <div className="row  d-flex align-items-center">
              <div className="col-12 col-lg-3 col-md-3 ">
                <div class="card ammenties-card d-flex justify-content-center align-items-center ">
                  <img class="" src={gym} alt="gym" />

                  <h5 class="card-title text-center">Gymnasium</h5>


                </div>
              </div>

              <div className="col-12 col-lg-3 col-md-3">
                <div class="card ammenties-card justify-content-center align-items-center  d-flex ">
                  <img class="" src={table} alt="table" />

                  <h5 class="card-title text-center">Snooker</h5>


                </div>
              </div>
              <div className="col-12 col-lg-3 col-md-3 ">
                <div class="card ammenties-card justify-content-center align-items-center d-flex ">
                  <img class="" src={carrom} alt="carrom" />

                  <h5 class="card-title text-center">Carrom </h5>


                </div>
              </div>
              <div className="col-12 col-lg-3 col-md-3">
                <div class="card  ammenties-card d-flex  justify-content-center align-items-center">
                  <img class="" src={dailyneed} alt="dailyneed" />

                  <h5 class="card-title text-center">	Daily Need Shops</h5>


                </div>
              </div>
            </div>

          </div>

        </div>
       
      </div>
       <div className="section-area section-sp bg-primary">
              <div className="container-fluid">
                <div className=" row text-center text-white style3">
                  <div className="col-md-6 phone-center ">
                    <h4 className="title-head p-t10 px-2 ">Book Your Dream Home Today with Pacific Eiffel Homes!</h4>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end px-4   phone-center align-items-center p-t10">
                    <button
                     
                      className="btn outline button-md m-b10 text-white"  onClick={handleBookNowClick}>
                     Book an Enquiry
                    </button>
                  </div>
                </div>
              </div>
            </div>

      <Footer2 />
    </div>

  );
}
