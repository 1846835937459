import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { client } from "../../../client";
// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

import ScrollToTop from "../../elements/ScrollToTop";
import bannerPic1 from "../../../images/contact.webp";
import Contact from "../../elements/contact/contact";
 


const Contact1 = () => {
  
 
  const [contact, setContact] = useState([]);
 
 

  useEffect(() => {
    const query = '*[_type == "contact"]';

    client.fetch(query).then((data) => setContact(data));
  }, []);

   

  return (
    <>
      <Header />
      <ScrollToTop />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark  ovdt2 parallax"
          style={{ backgroundImage: "url(" + bannerPic1 + ")" }}
        >
         
        </div>
        <div className="content-block" id="content-area">
          <div className="section-area section-sp1">
            <div className="container">
              <h4 className="text-left ">CONTACT US</h4> <hr />
              <div className="row m-t30">
                <div className="col-md-8 row">
                   {contact.sort((a, b) => a.num - b.num).map((item) => (
                  <div
                    className="col-lg-6 col-md-6  col-sm-12 m-b50 fadeInUp"
                    data-wow-delay="0.6s"
                  >
                    <div className="d-flex justify-content-between bb ">
                      <div className="left">
                      <h6 className="title"> {item.title} </h6>
                      <p>
                        
                        <i className="ti-location-pin"></i> {item.address}
                      </p>
                      <p>
                        
                        <i className="ti-user"></i> {item.person}
                      </p>
                      <p>
                        
                        <i className="ti-mobile"></i> {item.number}
                      </p>
                      <p>
                        
                        <i className="ti-email"></i> {item.email}
                      </p>
                      <Link
                        className="text-left"
                        onClick={() => window.open(`${item.location1link}`)}
                      >
                        <h6> {item.location1}</h6>
                      </Link>
                      </div>
                    </div> 
                  </div>
                ))}
                </div>

                <div className="col-md-4 ">

              <Contact  />

                </div>
               
              </div>
            </div>
          </div>
          <div className="section-area section-sp4">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-12 col-md-12 wow fadeIn"
                  data-wow-delay="0.4s"
                >
                 
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>

      <Footer />
       

    </>
  );
};

export default Contact1;
