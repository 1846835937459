import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom' 

// Home Pages
import Index from './pages/home/index';

// About Pages
import About from './pages/about/about';
import Corporate from './pages/about/corporate-profile';
import Csr from './pages/about/csr-policy';
import FuturePlans from './pages/about/future-plans';
import Management from './pages/about/management-team';
import Milestones from './pages/about/milestones';
import Mission from './pages/about/mission-values';
import Vigil from './pages/about/vigil-mechanism';
import Pce from './pages/about/principal-contractors-employers';


// Education Pages
import Education from './pages/education/education';

// Contact Pages
import Contact1 from './pages/contact/contact';

// Hospitality Pages
import Hospitality from './pages/hospitality/hospitality';
import Hotels from './pages/hospitality/hotels';
import HotelDetails from './pages/hospitality/hotels-detail';
import FoodCourt from './pages/hospitality/food-court';
import FoodCourtDetails from './pages/hospitality/food-court-details';

// Real Estate Pages
import RealEstate from './pages/real-estate/real-estate';
import Commercial from './pages/real-estate/commercial';


// Media Pages
import Media from './pages/media/media';

// Career Page
import Careers from './pages/careers/careers';

// Error Pages
import Error404 from './pages/error/error-404';

// Policy Pages
import Privacy from './pages/policies/privacy';
import Refund from './pages/policies/cancellation-and-refund-policy';
import TermsConditions from './pages/policies/terms-and-conditions';
// Project Pages
import Ourproject from './pages/projects/our-project';
import UpcomingProjects from './pages/projects/upcoming-projects';
import Blog from './pages/blog/blog';
import Blogpost from './pages/blog/blogPost';
import Appterms from './pages/terms/pacific-malls-app-terms-and-conditions';
import RewardTerms from './pages/terms/my-pacific-rewards-app-terms-and-conditions';
import PrivacyPolicy from './pages/terms/pacific-malls-app-privacy-policy';
import Booknow from './pages/bookingpage/booknow'
class Markup extends Component{
	render(){
		return(
			<>
				<BrowserRouter>
					<Routes>
						{/* Home Pages */}
						<Route path='/' element={<Index/>} />
						
						{/* About Pages */}					
						<Route path='/about' element={<About/>} />
						<Route path='/corporate-profile' element={<Corporate/>} />
						<Route path='/csr-policy' element={<Csr/>} />
						<Route path='/vigil-mechanism' element={<Vigil/>} />
						<Route path='/milestones' element={<Milestones/>} />
						<Route path='/future-plans' element={<FuturePlans/>} />
						<Route path='/management-team' element={<Management/>} />
						<Route path='/mission-values' element={<Mission/>} />
						<Route path='/principal-contractors-employers' element={<Pce/>} />


						{/* Hospitality Pages */}					
						<Route path='/hospitality' element={<Hospitality/>} />
						<Route path='/hotels' element={<Hotels/>} />
						<Route path='/hotels/:slug' element={<HotelDetails/>} />
						<Route path='/food-court' element={<FoodCourt/>} />
						<Route path='/food-court/:slug' element={<FoodCourtDetails/>} />

						{/* Real Estate Pages */}
						<Route path='/real-estate' element={<RealEstate/>} />
						<Route path='/real-estate/:slug' element={<Commercial/>} />
					
						{/* Media Page */}
						<Route path='/media' element={<Media/>} />

						{/* Education Page */}
						<Route path='/education' element={<Education/>} />

						{/* Projects Pages */}
						<Route path='/our-project' element={<Ourproject/>} />
						<Route path='/upcoming-projects' element={<UpcomingProjects/>} />
						{/* Careers Page */}
						<Route path='/careers' element={<Careers/>} />
						{/* Contact Page */}
						<Route path='/contact' element={<Contact1/>} />
						{/* Blog Pages */}
						<Route path='/blog' element={<Blog/>} />
						<Route path='/blog/:slug' element={<Blogpost/>} />
						{/* policy Pages */}
						<Route path='/privacy' element={<Privacy/>} />
						<Route path='/terms-and-conditions' element={<TermsConditions/>} />
						<Route path='/cancellation-and-refund-policy' element={<Refund/>} />
						{/* Error Pages */}
						<Route path='/*' element={<Error404/>} />

						{/* app terms pages */}
						<Route path='/pacific-malls-app-terms-and-conditions' element={<Appterms/>} />
						<Route path='/my-pacific-rewards-app-terms-and-conditions' element={<RewardTerms/>} />
						<Route path='/pacific-malls-app-privacy-policy' element={<PrivacyPolicy/>} />
						<Route path='/eiffel-homes' element={<Booknow/>}/>
					</Routes>
				</BrowserRouter>
				
				{/* <BackToTop /> */}
				
			</>
		);
	}
}

export default Markup;